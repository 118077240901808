import { NgModule, inject } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard } from './services/auth-guard.service'

import { LoginComponent } from './component/login/login.component'
import { CreateUserComponent } from './component/user/user.component'
import { QrcodeComponent } from './component/qrcode/qrcode.component'
import { ProfileComponent } from './component/profile/profile.component'
import { CreatePatientComponent } from './component/create-patient/create-patient.component'

const routes: Routes = [
	{ path: '', component: QrcodeComponent },
	// { path: 'login', component: LoginComponent },
	{ path: 'createuser', loadComponent: () => import('./component/user/user.component').then((m) => m.CreateUserComponent) },
	{
		path: 'register',
		loadComponent: () => import('./component/create-patient/create-patient.component').then((m) => m.CreatePatientComponent),
		canActivate: [AuthGuard],
	},
	{ path: 'profile', loadComponent: () => import('./component/profile/profile.component').then((m) => m.ProfileComponent) },
	{ path: '**', component: QrcodeComponent },
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
