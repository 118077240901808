import { Component } from '@angular/core'
import { faTriangleExclamation, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Config } from '../../../config'
import { Router } from '@angular/router'
import { SessionService } from 'src/app/services/session.service'
import { TranslateModule } from '@ngx-translate/core';
import { LoginformComponent } from '../../elements/loginform/loginform.component';
import { NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss'],
    standalone: true,
    imports: [
        FaIconComponent,
        NgIf,
        LoginformComponent,
        TranslateModule,
    ],
})
export class QrcodeComponent {
	faTriangleExclamation = faTriangleExclamation
	faChevronLeft = faChevronLeft
	ConfigName: string

	isBrandVisionix: boolean

	constructor(public session: SessionService, private router: Router) {
		this.ConfigName = Config.BUILD
		this.isBrandVisionix = this.session.isBrand(Config.BR_DEFAULT)
	}

	public gotoCreateUser() {
		this.router.navigate(['/createuser'])
	}
}
