import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import { JwtService } from 'src/app/services/jwt.service';

import {faUser,faLock, faEye, faEyeSlash,} from '@fortawesome/free-solid-svg-icons';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
// import { NavigationStart, Router } from '@angular/router';

// import { filter } from 'rxjs/operators';
// import { DataModelService } from 'src/app/services/data-model.service';
import { Config } from 'src/config';
import { NgClass } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'loginform',
    templateUrl: './loginform.component.html',
    styleUrls: ['./loginform.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        FaIconComponent,
        NgClass,
        TranslateModule,
    ],
})
export class LoginformComponent implements OnInit {
  userName: string;
  userPassword: string;

  showPwd: boolean;
  secret: string;

  // tokenbridge: string;
  // callback: string;
  // tokenNexus: string;

  // subscribe: string;

  // cache controller
  // oldFeBuild: boolean;

  faUser = faUser;
  faCircleUser = faCircleUser;
  faLock = faLock;
  faEyeOpen = faEye;
  faEyeClosed = faEyeSlash;

  constructor(
    public session: SessionService,
    // private jwt: JwtService,
    public translator: TranslateService,
    // private router: Router,
    // private dataModel: DataModelService
  ) {
    this.userName = '';
    this.userPassword = '';

    this.showPwd = false;

    // this.tokenbridge = 'field1';
    // this.callback = 'field2';
    // this.tokenNexus = 'bridgetoken';
    // this.subscribe = Config.subscribe;

    
  }

  ngOnInit(): void {}

  login(form) {
    // console.log(form);

    let usrN = '';
    let pwd = '';

    if (form) {
      usrN = form.value.usern;
      pwd = form.value.passw;
    } else {
      usrN = this.userName;
      pwd = this.userPassword;
    }

    usrN = usrN.trim();
    pwd = pwd.trim();
    // this.encode();

    this.session.login(usrN, pwd).then((resp)=>{
      console.log(resp)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  logout() {}

  // encode() {
  //   let payload = {
  //     iss: this.tokenbridge,
  //     from: this.callback,
  //     token: this.tokenNexus,
  //   };

  //   this.jwt
  //     .encodeJWT(payload)
  //     .then((result) => {
  //       console.log(result);

  //       this.session.jwt = result;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  clickTogglePassword() {
    this.showPwd = !this.showPwd;
  }

  goToRecoverPwdModal() {
    window.location.href = Config.nexusFeUrlRecover + '';
  }
}
