import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { bootstrapApplication, BrowserModule } from '@angular/platform-browser'
import * as Sentry from '@sentry/angular'
// import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component'

import { environment } from './environments/environment'
import { Config } from './config'
import { JwtModule } from '@auth0/angular-jwt'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { InternationalizationModule } from './app/internationalization/internationalization.module'
import { MatSliderModule } from '@angular/material/slider'
import { MatInputModule } from '@angular/material/input'
import { NgOtpInputModule } from 'ng-otp-input'
import { provideAnimations } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app/app-routing.module'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http'
import { CdTimerModule } from 'angular-cd-timer'
import { Router } from '@angular/router'
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter'
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule, MatRippleModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { TranslateStore, TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { DatePipe } from '@angular/common'

document.title = environment.brandTitle

Config.load(environment)

if (environment.production) {
	enableProdMode()
}

if (environment.brand == Config.BR_RDS) {
	document.body.classList.add('rds')
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '../assets/locales/', '.json')
}

export function tokenGetter() {
	return localStorage.getItem('access_token')
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			CdTimerModule,
			BrowserModule,
			NgbModule,
			FormsModule,
			FontAwesomeModule,
			ReactiveFormsModule,
			AppRoutingModule,
			NgbModule,
			NgOtpInputModule,
			MatDatepickerModule,
			MatNativeDateModule,
			MatInputModule,
			MatRippleModule,
			MatSliderModule,
			InternationalizationModule.forRoot({ locale_id: 'en_EN' }),
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			JwtModule.forRoot({
				config: {
					tokenGetter: tokenGetter,
					allowedDomains: [''],
					disallowedRoutes: [''],
				},
			})
		),
		DatePipe,
		NgbModal,
		TranslateStore,
		MatDatepickerModule,
		// // these providers are necessary for material datepicker
		{ provide: MAT_DATE_LOCALE, useValue: 'en_EN' },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
	],
}).catch((err) => console.error(err))

let env = 'localhost'
let maskAllTextHide = true
let blockAllMediaHide = true

let tracesSampleRate = 0.25
let replaysSessionSampleRate = 0 // 0.1 we have 50 replay/month before to enable only on production, is necessary to hide all personal infomations
let replaysOnErrorSampleRate = 0 // 0.1 we have 50 replay/month before to enable only on production, is necessary to hide all personal infomations

if (environment.production) {
	env = 'production'
	maskAllTextHide = true
	blockAllMediaHide = true
}

if (environment.staging) {
	env = 'staging'
	maskAllTextHide = true
	blockAllMediaHide = true
}

if (environment.development) {
	env = 'development'
	tracesSampleRate = 1
	replaysSessionSampleRate = 0
	replaysOnErrorSampleRate = 0
	maskAllTextHide = false
	blockAllMediaHide = false
}

if (environment.development || environment.staging || environment.production) {
	//SENTRY
	Sentry.init({
		debug: false,
		dsn: 'https://ca950aa523a97f975c82aaa79d6293c9@o4506858692411392.ingest.us.sentry.io/4506897592418304',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: maskAllTextHide,
				blockAllMedia: blockAllMediaHide,
			}),
		],
		environment: env,
		release: 'nexus.subscribe@' + Config.BUILD,
		// Performance Monitoring
		tracesSampleRate: tracesSampleRate, //  Capture 100% of the transactions

		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [environment.apiEndpoint, environment.api_bridgeEndpoint],

		// Session Replay
		replaysSessionSampleRate: replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

// bootstrapApplication(appConfig, AppComponent)
// .catch((err) => console.error(err));
