import { Agreeement } from './agreements.model'
import { Anamnesis, PatientReply } from './anamnesis.model'
import { Country } from './countries.models'
import { TokenResponse } from './user.model'
import { CryptoUtilsService } from '../services/crypto-utils.service'

export class Patient {
	priv_data: string
	unique_hash: string
	owner_id: number //user id
	group_id: number // group id , nel caso di TypeA = 0
	uuid: string
	firstName: string
	lastName: string
	birthDate: string
	personal_id: string
	gender: number
	country: string
	countryObj: Country
	phoneNumber: string
	email: string
	medicalAnamnesis: Anamnesis[]
	impactAnamnesis: Anamnesis[]
	medicalReply: PatientReply[]
	impactReply: PatientReply[]
	agreement: Agreeement
	agreementAccepted: boolean
	tokenResponse: TokenResponse

	constructor(data?) {
		this.priv_data = ''
		this.unique_hash = ''
		this.owner_id = 0
		this.group_id = 0
		this.uuid = ''
		this.firstName = ''
		this.lastName = ''
		this.birthDate = ''
		this.personal_id = ''
		this.gender = 3
		this.country = ''
		this.countryObj = null
		this.phoneNumber = ''
		this.email = ''
		this.medicalAnamnesis = []
		this.impactAnamnesis = []
		this.medicalReply = []
		this.impactReply = []
		this.agreement = null
		this.agreementAccepted = false
		this.tokenResponse = null
	}

	public getPatientPrivData() {
		let data = {
			firstname: this.firstName,
			lastname: this.lastName,
			birthdate: this.birthDate,
			personalid: this.personal_id,
			email: this.email,
			phonenumber: this.phoneNumber,
		}
		return data
	}

	public setUniqueHash(cryptoUtils: CryptoUtilsService) {
		let ownerId

		if (this.group_id > 0) {
			ownerId = this.group_id
		} else {
			ownerId = this.owner_id
		}
		let myString = (this.firstName + this.lastName + this.birthDate + ownerId).toLowerCase()
		this.unique_hash = cryptoUtils.getSHA256(myString).toString()
	}
}

export class patObj {
	//object ready to save
	owner_id: number // sará lo userId nel caso di typeA, sará il groupId nel caso di gruppi
	created_by: number // sará sempre lo userId (ownerid)
	priv_data: string
	sex: number
	country: string
	unique_hash: string
	email: string
	consent: string
	medicalAnamnesis: PatientReply[]
	impactAnamnesis: PatientReply[]
	uuid: string

	constructor(obj: Patient) {
		if (obj.group_id > 0) {
			this.owner_id = obj.group_id
		} else {
			this.owner_id = obj.owner_id
		}
		this.created_by = obj.owner_id
		this.priv_data = obj.priv_data
		this.sex = obj.gender
		this.country = obj.country
		this.unique_hash = obj.unique_hash
		this.email = obj.email
		this.consent = obj.agreement.id.toString()
		this.medicalAnamnesis = obj.medicalReply
		this.impactAnamnesis = obj.impactReply
		this.uuid = obj.uuid
	}
}

export interface PatientCreationObj {
	patient: PatientObj
	step?: number
}

export class PatientObj {
	id: number
	patient: Patient

	constructor(pat?: Patient) {
		this.id = 0
		this.patient = pat
	}
}

export class patRegistration {
	isFromQr: boolean
	pc_userId: string
	pc_groupId: string
	pc_anamId: string
	pc_impactAnamId: string
	pc_country: string
	pc_uuid: string
	isPatAut: boolean

	constructor() {
		this.isFromQr = false
		this.isPatAut = false
		this.pc_anamId = ''
		this.pc_impactAnamId = ''
		this.pc_groupId = ''
		this.pc_userId = ''
		this.pc_country = ''
		this.pc_uuid = ''
	}
}
